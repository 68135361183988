import React from "react";
import Seo from "../../components/seo";
import Layout from "../../components/Layout";
import HeroSection from "../../components/WeAreHiringIndividual/HeroSection";
import BodySection from "../../components/WeAreHiringIndividual/bodySection";

import { graphql } from "gatsby"
import get from "lodash/get"

const WeAreHiringIndividual= ({ data, pageContext: { uid } }) => {

    const practiceArea = data.prismicCareersPage;

    const dataSet = get(practiceArea, "dataRaw", []);
    console.log(dataSet)

    const hero = {
        heroTitle: dataSet.title,
        heroSubTitle: dataSet.sub_title,
        heroJobTag: dataSet.job_tag
    }
    const body = {
        bodyTitle: dataSet.title,
        bodySubTitle: dataSet.sub_title,
        bodyDescription: dataSet.description
    }

    return (
        <Layout>
            <HeroSection data={hero}/>
            <BodySection data={body}/>
        </Layout>
    );
};
//job_tag  location  responsibilities reuirement working_time
// export const Head = ({ data }) => {
//     return <Seo title={'We Are Hiring Individual'} description={'excerpt'} />;
// };
export default WeAreHiringIndividual;


export const query = graphql`
  query WeAreHiringIndividual($uid: String) {
    prismicCareersPage(uid: { eq: $uid }) {
      uid
      dataRaw
    }
  }
`
