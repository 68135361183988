import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import * as cs from './index.module.scss';
import Form from "react-bootstrap/Form";
import axios from "axios";
import {PrismicRichText} from "@prismicio/react";

const isDev = (value) => (process.env.NODE_ENV === "development" ? value : "");

const BodySection = ({data}) => {

    const [txtFile, setTxtFile] = useState("");
    const [txtFirstAndLastName, setTxtFirstAndLastName] = useState("");
    const [txtEmail, setTxtEmail] = useState("");
    const [txtDescriptionYourSelf, setTxtDescriptionYourSelf] = useState("");
    const [success, setSuccess] = useState(false);
    const [attachment, setAttachment] = useState(null);
    const [fail, setFail] = useState(false);
    const [progress, setProgress] = useState(false);
    const [txtFileSelect, setTxtFileSelect] = useState("");

    const encodeFile = async (e) => {
        if (e.target.files.length > 0) {
            let filename = e.target.files[0].name;
            setTxtFileSelect(filename)
        }
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }
        const file = e.target.files[0];
        if (file.size > 2 * 1024 * 1024) {
            return;
        }
        setProgress(true);
        let reader = new FileReader();
        if (file) {
            await reader.readAsDataURL(file);
            reader.onload = () => {
                let base64 = reader.result.replace("data:", "").replace(/^.+,/, "");
                setAttachment(base64);
            };
        }
        console.log(attachment);
        setProgress(false);
    };

    const title = data.bodyTitle[0].text+" "+data.bodySubTitle[0].text;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFail(false);
        setProgress(true);

        await axios
            .post("/api/career-submission", {
                attachment,
                txtFirstAndLastName,
                fields: [
                    { key: "Title", value: title },
                    { key: "First And Last Name", value: txtFirstAndLastName },
                    { key: "Email", value: txtEmail },
                    { key: "Description Your Self", value: txtDescriptionYourSelf },
                ],
            })
            .then(() => {
                setSuccess(true);
                setProgress(false);
                setTxtDescriptionYourSelf("");
                setTxtFirstAndLastName("");
                setTxtEmail("");
            })
            .catch(() => {
                setFail(true);
                setInterval(() => {
                    setFail(false);
                }, 6000);
            });
        setProgress(false);
    };

    return (
        <section className="requirement_section">
            <Container className={cs.com_container}>
                <Row className={`${cs.com_row} ${cs.com_row_1}`}>
                    <Col xxxl={1} xxl={1} xl={1}></Col>
                    <Col xxxl={10} xxl={10} xl={10} lg={12} md={12} sm={12} xs={12} xxs={12} className={cs.com_cel}>
                        <div className={cs.contact_us_responsibilities_section}>
                            <PrismicRichText field={data.bodyDescription}/>
                        </div>
                    </Col>
                    <Col xxxl={1} xxl={1} xl={1}></Col>
                </Row>

                <Form action="" method="post" className="forms"
                      onSubmit={(e) => {
                          handleSubmit(e);
                      }}
                >
                    <Row className={`${cs.com_row} ${cs.com_row_2}`}>

                        <Col xxxl={1} xxl={1} xl={1}></Col>
                        <Col xxxl={10} xxl={10} xl={10} lg={12} md={12} sm={12} xs={12} xxs={12} className={cs.com_cel}>

                            {/*Form File Upload Text*/}
                            <div className={`${cs.lbl_form} ${cs.lbl_form_1}`}>
                                Upload your CV in PDF format
                            </div>

                            <div className={cs.zone}>
                                <div className={cs.dropZ}>
                                    <div className={cs.selectFile}>
                                        <label htmlFor="file">
                                            Drop your file here or <strong>browse</strong>
                                        </label>
                                        <input type="file" name="files[]" id="file"
                                               accept={"application/pdf"}
                                               onChange={encodeFile}
                                        />
                                    </div>
                                    <input className={cs.file_select_name}
                                        value={txtFileSelect}
                                           disabled
                                        onChange={(e) => setTxtFileSelect(e.target.value)}>
                                    </input>
                                    <p>
                                        Maximum file size: 10MB
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col xxxl={1} xxl={1} xl={1}></Col>

                        <Col xxxl={1} xxl={1} xl={1}></Col>
                        <Col xxxl={10} xxl={10} xl={10} lg={12} md={12} sm={12} xs={12} xxs={12} className={cs.com_cel}>

                            {/*Form Name Text*/}
                            <div className={cs.lbl_form}>
                                Your Name
                            </div>

                            {/*Form Name Input*/}
                            <Form.Control className={cs.form_box_input}
                                          type="text"
                                          placeholder="Enter your first & last names"
                                          id="txtFirstAndLastName" value={txtFirstAndLastName}
                                          onChange={(e) => setTxtFirstAndLastName(e.target.value)}
                            />

                        </Col>
                        <Col xxxl={1} xxl={1} xl={1}></Col>

                        <Col xxxl={1} xxl={1} xl={1}></Col>
                        <Col xxxl={10} xxl={10} xl={10} lg={12} md={12} sm={12} xs={12} xxs={12} className={cs.com_cel}>

                            {/*Form Email Text*/}
                            <div className={cs.lbl_form}>
                                Your email address
                            </div>

                            {/*Form Email Input*/}
                            <Form.Control className={cs.form_box_input} type="text" id="txtEmail" value={txtEmail}
                                          placeholder="Ex: john@gmail.com"
                                          onChange={(e) => setTxtEmail(e.target.value)}
                            />

                        </Col>
                        <Col xxxl={1} xxl={1} xl={1}></Col>

                        <Col xxxl={1} xxl={1} xl={1}></Col>
                        <Col xxxl={10} xxl={10} xl={10} lg={12} md={12} sm={12} xs={12} xxs={12} className={cs.com_cel}>

                            {/*Form Description Text*/}
                            <div className={cs.lbl_form}>
                                Briefly tell us about yourself
                            </div>

                            {/*Form Description Input*/}
                            <Form.Control className={cs.form_box_input_text_area}
                                          as="textarea"
                                          placeholder="Ex: Your experience, achievements, and things you’re generally proud of"
                                          rows={3} id="txtDescriptionYourSelf"
                                          value={txtDescriptionYourSelf}
                                          onChange={(e) => setTxtDescriptionYourSelf(e.target.value)}
                            />

                        </Col>
                        <Col xxxl={1} xxl={1} xl={1}></Col>

                        <Col xxxl={1} xxl={1} xl={1}></Col>

                        <Col xxxl={10} xxl={10} xl={10} lg={12} md={12} sm={12} xs={12} xxs={12} className={cs.com_cel}>
                            {success && (
                                <div className="form-success"> Thank you for applying. </div>
                            )}
                            {fail && (
                                <div className="form-fail">
                                    {" "}
                                    Form submission failed. Please try again.{" "}
                                </div>
                            )}

                            {/*Form Button Text*/}
                            <Button className={cs.contact_us_section_content_box_button}
                                    type="submit"
                                    disabled={progress || success}
                                    variant="primary"
                            >
                                Submit
                            </Button>

                        </Col>
                        <Col xxxl={1} xxl={1} xl={1}></Col>
                    </Row>
                </Form>
            </Container>
        </section>
    );
};


export default BodySection;