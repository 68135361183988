// extracted by mini-css-extract-plugin
export var com_cel = "index-module--com_cel--499ba";
export var com_container = "index-module--com_container--38aee";
export var com_row = "index-module--com_row--f2556";
export var com_row_2 = "index-module--com_row_2--55290";
export var contact_us_responsibilities_section = "index-module--contact_us_responsibilities_section--1290b";
export var contact_us_section_content_box_button = "index-module--contact_us_section_content_box_button--aceea";
export var contact_us_section_content_box_title = "index-module--contact_us_section_content_box_title--73753";
export var file_select_name = "index-module--file_select_name--440be";
export var form_box = "index-module--form_box--34fbb";
export var form_box_input = "index-module--form_box_input--80a73";
export var form_box_input_bottom = "index-module--form_box_input_bottom--ac9e8";
export var form_box_input_text_area = "index-module--form_box_input_text_area--3734c";
export var form_box_label_text_area = "index-module--form_box_label_text_area--81d7a";
export var lblBrowse = "index-module--lblBrowse--6375c";
export var lbl_form = "index-module--lbl_form--efa79";
export var lbl_form_1 = "index-module--lbl_form_1--ac39a";
export var selectFile = "index-module--selectFile--1821b";
export var zone = "index-module--zone--6c19b";