import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as cs from './index.module.scss';
import {PrismicRichText} from "@prismicio/react";
import { PrismicText } from "@prismicio/react";

const HeroSection = ({data}) => {

    const title = data.heroTitle[0].text+" "+data.heroSubTitle[0].text;

    return (
        <header>
            <Container className={cs.com_container}>
                <Row className={cs.com_row}>
                    <Col xxxl={1} xxl={1} xl={1}></Col>
                    <Col xxxl={10} xxl={10} xl={10} lg={12} md={12} sm={12} xs={12} xxs={12} className={cs.com_cel}>
                        <div className={cs.contact_us_hero_section_content_box}>
                            {/*Title*/}
                            <div className={cs.contact_us_hero_section_content_title}>
                                <h1>{title}</h1>
                            </div>
                        </div>
                    </Col>
                    <Col xxxl={1} xxl={1} xl={1}></Col>

                    <Col xxxl={1} xxl={1} xl={1}></Col>
                    <Col xxxl={10} xxl={10} xl={10} lg={12} md={12} sm={12} xs={12} xxs={12} className={cs.com_cel}>
                        <div className={`${cs.contact_us_hero_section_content_box} ${cs.contact_us_hero_section_content_box_bootom}`}>
                            {
                                data.heroJobTag.map((item, idx)=>{
                                    return(
                                        <label className={cs.contact_us_hero_section_content_box_label}>
                                            {item.job_tag_name}
                                        </label>
                                    )
                                })
                            }
                        </div>
                    </Col>
                    <Col xxxl={1} xxl={1} xl={1}></Col>
                </Row>
            </Container>
        </header>
    );
};


export default HeroSection;